import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
SwiperCore.use([Navigation]);
import {GatsbyImage} from "gatsby-plugin-image";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import HeaderHome from "../components/header/headerHome/headerHome";
import JobLine from "../components/jobLine/jobLine";
import LastArticles from "../components/lastArticles/lastArticles";
import Annonce from "../components/annonce/annonce";

import "./style.scss";
import ReactMarkdown from "react-markdown";
import ContactezNous from "../components/contactezNous/contactezNous";
import FourBoxes from "../components/fourBoxes/fourBoxes";
import EtreSocietaire from "../components/etreSocietaire/etreSocietaire";
import ThreeBoxesMobile from "../components/threeBoxesMobile/threeBoxesMobile";
import Support from "../components/support/support";
import Button from "../components/button/button";


const IndexPage = ({ data }) => {
  const metiers = data.allStrapiHome.nodes[0].Metiers;
  const slider = data.allStrapiHome.nodes[0].Slider;
  const lastArticles = data.lastArticles.nodes;
  const produits = data.allStrapiProduit.nodes;
  const annonce = data.allStrapiAnnonce.nodes[0];

  metiers.forEach((metier) => {
    metier.produit_slug = produits.find(
      (produit) => produit.strapi_id === metier.Produit.strapi_id
    ).Slug;
  });

  if (slider.Images.length === 1) {
    // si on a une seule image dans le slider alors on l'ajoute encore une fois dans la liste pour prevent un bug du Swiper
    slider.Images = [slider.Images[0], slider.Images[0]];
  }

  const imagesAfficher = slider.Images.filter((i) => i.AfficherSiteInternet);

  return (
    <>
      <Helmet>
        <body className="index" />
      </Helmet>
      <Layout>
        <SEO
          newTitleTemplate="%s · Assurance BTP"
          title="L'Auxiliaire BTP"
          description="Architecte, entreprise du bâtiment, ingénieur, artisan, fabricant, négociant, promoteur, L'Auxiliaire BTP, l'assurance des professionnels du bâtiment et des travaux publics."
        />
        <Annonce annonce={annonce} />
        <HeaderHome
          headerBackground={data.headerBackground.childImageSharp.fluid}
        />
        <section className="index-section index-section__assurances">
          <h2 className="index-section-headline centered-headline">
            <strong className="desktop-text">Des assurances</strong>
            <span className="desktop-text"> de haute technicité</span> <br />
            <span className="desktop-text">
              <strong>pour tous les métiers du BTP</strong>&nbsp;!
            </span>
            <span className="mobile-text">
              {" "}
              <strong>Des assurances</strong> de haute{" "}
              <strong>technicité</strong> <br />{" "}
            </span>
            <span className="mobile-text">
              {" "}
              pour tous les métiers{" "}
              <strong className="mobile-text">du BTP</strong>&nbsp;! <br />{" "}
            </span>
          </h2>
          <JobLine metiers={metiers} />
          <div className="button-jobLine-container">
            <Button className="assurance-btn" text="Nos assurances" />
            <Button className="agences-btn" text="Nos agences" />
          </div>
          <div className="button-jobLine-container button-jobLine-container__centered">
            <Button className="courtage-btn" text="Service courtage" />
          </div>
        </section>

        <section className="index-section index-section-support">
          <Support
            nosValeurs1={data.nosValeurs1.childImageSharp.fluid}
            nosValeurs2={data.nosValeurs2.childImageSharp.fluid}
          />
        </section>

        {imagesAfficher.length > 0 && (
          <section className="index-section-slider">
            <h2 className="index-section-headline">
              <ReactMarkdown children={slider.Titre} />
            </h2>

            {imagesAfficher.length === 1 && (
              <div className="center-fixed-image-slider">
                {imagesAfficher[0].Image.localFile && (
                  <a
                    className="center-fixed-image-slider__single"
                    {...(imagesAfficher[0].Url !== " " &&
                    imagesAfficher[0].Url != null
                      ? { href: imagesAfficher[0].Url }
                      : {})}
                  >
                    <GatsbyImage
                      image={
                        imagesAfficher[0].Image.localFile.childrenImageSharp[0]
                          .gatsbyImageData
                      }
                      alt={``}
                    />
                  </a>
                )}
              </div>
            )}
            {imagesAfficher.length === 2 && (
              <div className="center-fixed-image-slider">
                {imagesAfficher[0].Image.localFile && (
                  <a
                    className="center-fixed-image-slider__single"
                    {...(imagesAfficher[0].Url !== " " &&
                    imagesAfficher[0].Url != null
                      ? { href: imagesAfficher[0].Url }
                      : {})}
                  >
                    <GatsbyImage
                      image={
                        imagesAfficher[0].Image.localFile.childrenImageSharp[0]
                          .gatsbyImageData
                      }
                      alt={`Image 1`}
                    />
                  </a>
                )}

                {imagesAfficher[1].Image.localFile && (
                  <a
                    className="center-fixed-image-slider__single"
                    {...(imagesAfficher[1].Url !== " " &&
                    imagesAfficher[1].Url != null
                      ? { href: imagesAfficher[1].Url }
                      : {})}
                  >
                    <GatsbyImage
                      image={
                        imagesAfficher[1].Image.localFile.childrenImageSharp[0]
                          .gatsbyImageData
                      }
                      alt={`Image 2`}
                    />
                  </a>
                )}
              </div>
            )}

            {imagesAfficher.length > 2 && (
              <Swiper
                slidesPerView={2.5}
                spaceBetween={10}
                centeredSlides={true}
                navigation={true}
                loop={true}
                autoplay={{
                  stopOnLastSlide: false,
                }}
                pagination={{
                  clickable: true,
                }}
              >
                {imagesAfficher.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div>
                      {image.Image.localFile && (
                        <a
                          {...(image.Url !== " " && image.Url != null
                            ? { href: image.Url }
                            : {})}
                        >
                          <GatsbyImage
                            image={
                              image.Image.localFile.childrenImageSharp[0]
                                .gatsbyImageData
                            }
                            alt={``}
                            key={index}
                          />
                        </a>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </section>
        )}

        <section className="index-section-desktop-view">
          <LastArticles articles={lastArticles} />
          <h2 className="index-section-headline">
            <strong>Être sociétaire</strong> de L’Auxiliaire BTP
          </h2>
          <EtreSocietaire
            etreSocietaireImg={data.etreSocietaireImg.childImageSharp.fluid}
          />
        </section>

        <section className="index-section-mobile-view">
          <LastArticles articles={lastArticles} />
          <h2 className="index-section-headline_societaire">
            <strong>Être sociétaire</strong> de L’Auxiliaire BTP
          </h2>
          <EtreSocietaire
            etreSocietaireImg={data.etreSocietaireImg.childImageSharp.fluid}
          />
        </section>

        <section className="index-section contactez-nous">
          <ContactezNous
            contactezNousImg={data.contactezNousImg.childImageSharp.fluid}
          />
        </section>

        <section className="index-section contactez-nous-mobile">
          <ContactezNous
            contactezNousImg={data.contactezNousImg.childImageSharp.fluid}
          />
        </section>

        <section className="index-section-box">
          <h2 className="index-section-headline">
            L’Auxiliaire BTP,
            <br />
            du sens <strong>à l’ouvrage</strong>
          </h2>
          <FourBoxes
            boxImage1={data.boxImage1.childImageSharp.fluid}
            boxImage2={data.boxImage2.childImageSharp.fluid}
            boxImage3={data.boxImage3.childImageSharp.fluid}
            boxImage4={data.boxImage4.childImageSharp.fluid}
          />
          <ThreeBoxesMobile
            boxImage1={data.boxImage1.childImageSharp.fluid}
            boxImage2={data.boxImage2.childImageSharp.fluid}
            boxImage3={data.boxImage3.childImageSharp.fluid}
            boxImage4={data.boxImage4.childImageSharp.fluid}
          />
        </section>
      </Layout>
    </>
  );
};

export const query = graphql`
  query HomePageQuery {
    boxImage1: file(relativePath: { eq: "home/nos-services.webp" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    boxImage2: file(relativePath: { eq: "home/chiffres-cles.webp" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    boxImage3: file(relativePath: { eq: "home/partenaires.webp" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    boxImage4: file(relativePath: { eq: "home/rejoindre.webp" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    contactezNousImg: file(relativePath: { eq: "home/contactez-nous.webp" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    nosValeurs1: file(relativePath: { eq: "home/nos-valeurs-1.webp" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 687) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    nosValeurs2: file(relativePath: { eq: "home/nos-valeurs-2.webp" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 509) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    etreSocietaireImg: file(relativePath: { eq: "home/etre-societaire.webp" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1149) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    headerBackground: file(
      relativePath: { eq: "home/header-background.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1061) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    mobileHeaderBackground: file(
      relativePath: { eq: "home/header-background-mobile.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1061) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allStrapiHome {
      nodes {
        Metiers {
          Titre
          Image_De_Couverture {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80)
              }
            }
          }
          Produit {
            strapi_id
          }
        }
        Slider {
          Titre
          Images {
            Url
            AfficherSiteInternet
            Image {
              localFile {
                childrenImageSharp {
                  gatsbyImageData(quality: 80)
                }
              }
            }
          }
        }
      }
    }

    lastArticles: allStrapiArticle(
      sort: { fields: [Important, Date_De_Publication], order: [DESC, DESC] }
      limit: 4
      filter: { Indexer: { eq: true } }
    ) {
      nodes {
        Important
        Titre
        Slug
        Date_De_Publication(formatString: "DD/MM/YYYY")
        Image_Vignette {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80)
            }
          }
        }
      }
    }

    allStrapiProduit {
      nodes {
        Slug
        strapi_id
      }
    }

    allStrapiAnnonce {
      nodes {
        Titre
        Message {
          data {
            Message
          }
        }
        Afficher
      }
    }
  }
`;

export default IndexPage;
