import React from "react";

import "./contactezNous.scss";
import BackgroundImage from "gatsby-background-image";
import { ShowContactContext } from "../layout/layout";

export default function ContactezNous({ contactezNousImg}) {
    return (
      <>
        <div className="contactez-nous">
          <div className="headline-and-button">
            <h2 className="index-section-headline-contact-text">
              Besoin d'un devis ?<br />
              <strong>Contactez-nous !</strong>
            </h2>
            <ShowContactContext.Consumer>
              {(showContact) => (
                <button onClick={showContact} className="contactez-nous-btn" >
                  <span>Prendre contact</span>
                </button>
                )}
            </ShowContactContext.Consumer>
          </div>
          <BackgroundImage
            Tag="section"
            fluid={contactezNousImg}
            preserveStackingContext
            className="full-width-background"
          />
        </div>

        <div className="contactez-nous-mobile">
          <div className="headline-and-button">
            <h2 className="index-section-headline">
              Besoin d'un devis ?<br />
              <strong>Contactez-nous !</strong>
            </h2>
            <ShowContactContext.Consumer>
              {(showContact) => (
                <button onClick={showContact} className="contactez-nous-btn" >
                  <span>Prendre contact</span>
                </button>
              )}
            </ShowContactContext.Consumer>
          </div>
          <BackgroundImage
            Tag="section"
            fluid={contactezNousImg}
            className="full-width-background"
          />
        </div>
      </>
    );
}
